@import 'icons';
@import 'react-phone-input-2/lib/style.css';


.react-tel-input .flag-dropdown.open .selected-flag {
  background: inherit;
  border-radius: 3px 0 0 0
}

.react-tel-input input[disabled] + .flag-dropdown:hover .selected-flag {
  background-color: inherit
}

.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
  background-color: inherit
}

.react-tel-input .country-list .country.highlight {
  background-color: green;

}

.react-tel-input .country-list .country:hover {
  background-color: darkgreen;
}
